import React from 'react'
import "./projects.css"
import Project from "../project/Project"
import {projects} from "../../data";

const Projects = () => {
  return (
    <div className="projects">
        <div className="projects-texts">
            <h1 className="projects-title">Projects</h1>
            <p className="projects-desc">
                Throughout college, I have created some projects I am proud of both for classes and personal ones! Feel free to check them out!

            </p>
       </div>
       <div className="projectList-list">
        {projects.map((item) => (
            <Project key={item.id} img={item.img} link={item.link} desc={item.desc} title={item.title}/>
        ))}
       </div>
    </div>
  )
}

export default Projects;
