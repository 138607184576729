import Landscapes from "../src/img/Landscapes-Long.jpg"
import Portraits from "../src/img/Portraits-Long.jpg"
import Events from "../src/img/Events-Long.jpg"

import Galaxfy from "../src/img/galaxfy-ss.png"
import Campuswire from "../src/img/campuswire-ss.png"
import uBudget from "../src/img/uBudget-ss.png"
import Photography from "../src/img/photoportfolio.png"

export const products = [
    {
        id: 1,
        img: Landscapes,
        link: "http://aaroncheng.photos/landscapes",
    },
    {
        id: 2,
        img: Portraits,
        link: "http://aaroncheng.photos/portraits",
    },
    {
        id: 3,
        img: Events,
        link: "http://aaroncheng.photos/events",
    },
]

export const projects = [
    {
        id: 1,
        img: Galaxfy,
        link: "https://www.galaxfy.app/",
        title: "Galaxfy",
        desc: "Data visualization web application with a React frontend and Node.js backend that generates a rotating text sphere of top tracks/artists from Spotify, deployed on Heroku."
    },
    {
        id: 2,
        img: Photography,
        link: "https://aaroncheng.photos/",
        title: "Photography Portfolio",
        desc: "A showcase of my photography work including portraits, landscapes, concerts, and events!"
    },
    {
        id: 3,
        img: uBudget,
        link: "https://drive.google.com/file/d/1JqJZ1OxPvijSvknx2CWdqDx7wxEGEc8g/view?usp=sharing",
        title: "uBudget",
        desc: "Mobile application to help college students and young adults budget better. Created using a React-Native Expo frontend alongside Google Firebase for the backend."
    },
    {
        id: 4,
        img: Campuswire,
        title: "Campuswire Analytics Dashboard",
        desc: "Class analytics dashboard exhibiting detailed and informative statistics/graphs from Campuswire for students, teachers, and admin use with a React frontend, and .NET backend."
    }
    
]