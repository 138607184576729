import React from 'react'
import "./contact.css"
import Phone from "../../img/phone.svg"
import Email from "../../img/email.svg"
import LinkedIn  from "../../img/linkedin.svg"
import Instagram  from "../../img/instagram.svg"
import { useRef, useState } from "react"
import emailjs from '@emailjs/browser';

const Contact = () => {
    const formRef = useRef()
    const [done, setDone] = useState(false);

    const submit = (e) => {
        e.preventDefault()
    
    emailjs.sendForm('service_x6mav7s', 'template_ecgsqvi', formRef.current, 'hEBwC3A8VGk8mm0zD')
      .then((result) => {
          console.log(result.text);
          setDone(true)
      }, (error) => {
          console.log(error.text);
      });
    }

  return (
    <div id="contact" className="contact">
      <div className="contact-bg"></div>
      <div className="contact-wrap">
        <div className="contact-left">
            <h1 className="contact-title">Contact Me</h1>
            <div className="contact-info">
                <div className="contact-info-item">
                    <img src={Email} alt="" className="contact-icon" />
                    aaroncheng@umass.edu
                </div>
                <div className="contact-info-item">
                    <img src={LinkedIn} alt="" className="contact-icon" />
                    <a href="https://www.linkedin.com/in/che-ngi/" target="_blank" rel="noreferrer">
                        https://www.linkedin.com/in/che-ngi/
                    </a>
                </div>
                <div className="contact-info-item">
                    <img src={Instagram} alt="" className="contact-icon"/>
                    <a href="https://www.instagram.com/che.ngi/" target="_blank" rel="noreferrer">
                        @che.ngi
                    </a>
                </div>
            </div>
        </div>
        <div className="contact-right">
            <p className="contact-desc">
                <b>Looking to collaborate or have an inquiry?</b> Feel free to send me a DM on any of my platforms
                or email me below!
            </p>
            <form ref={formRef} onSubmit={submit}>
                <input type="text" placeholder="Name" name="user_name"></input>
                <input type="text" placeholder="Subject" name="user_subject"></input>
                <input type="text" placeholder="Email" name="user_email"></input>
                <textarea rows="5" placeholder="Message" name="message"/>
                <button className="submitBtn">Submit</button>
                {done && 
                    <div className="popUp">
                        <p className="submitMsg">Thanks! I will get back to you shortly!</p>
                    </div>}
            </form>     
        </div>
      </div>
    </div>
  )
}

export default Contact;