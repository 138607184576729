import "./navBar.css"
import React from 'react'

const NavBar = () => {
  return (
    <div className="navBar">
        <nav className="navWrap">
            <a href="#intro" className="nav-item">Me</a>
            <a href="#about" className="nav-item">About Me</a>
            <a href="#productList" className="nav-item">Photography</a>
            <a href="#contact" className="nav-item">Contact</a>
        </nav>
    </div>
  )
}

export default NavBar