import React from 'react'
import Product from "../product/Product"
import "./productList.css"
import {products} from "../../data";

const ProductList = () => {
  return (
    <div id="productList" className="productList">
       <div className="productList-texts">
        <h1 className="productList-title">Photography</h1>
        <p className="productList-desc">
            Over 8 years of photography experience accumulated to build a portfolio I am proud of.
            I have shot ceremonies, school events, portraits, landscapes, and my favorite cityscapes.
            I was the photographer for my school's Asian American Student Association for 2 years!
        </p>
       </div>
       <div className="productList-list">
        {products.map((item) => (
            <Product key={item.id} img={item.img} link={item.link}/>
        ))}
       </div>
    </div>
  )
}

export default ProductList
