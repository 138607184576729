import NavBar from "./components/navBar/NavBar";
import Intro from "./components/intro/intro";
import About from "./components/about/About"
import ProductList from "./components/productList/ProductList"
import Contact from "./components/contact/Contact";
import Projects from "./components/projects/Projects";

const App = () => {
  return ( 
  <div>
    {/* <NavBar/> */}
    <Intro/>
    <About/> 
    <Projects/>
    <ProductList/>
    <Contact/>
  </div>
);
};

export default App;