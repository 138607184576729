import React from 'react'
import "./product.css"

const Product = ({img, link}) => {
  return (
    <div className="product">
        <a href={link} target="_blank" rel="noreferrer">
            <img src={img} alt="" className="product-img"></img>
        </a>
    </div>
  )
}

export default Product
