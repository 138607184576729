import React from 'react'
import "./project.css"

const Project = ({img, link, title, desc}) => {
  return (
    <div className="projCont">
            <div className="project">
                <a href={link} target="_blank" rel="noreferrer">
                    <img src={img} alt="" className="project-img"></img>
                </a>
            </div>
            <div className="infoCont">
                <h2 className="title">
                    {title}
                </h2>
                <p className='desc'>
                    {desc}
                </p>
            </div>
    </div>
  )
}

export default Project
